<div class="main-content">
<!--  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>-->
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Inventory Levels by SKU and Location'"
      [formClass]="formClass"
      [hasBulkUpload]="false"
      [excludeColumns]="4"
      [hideAddButton]="true"
      [filter]="filter"
      (rowClick)="onRowTap($event)"
      [clickableRows]="true"
      [enableRefresh]="true"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
